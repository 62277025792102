import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import BannerSection from '../containers/Saas/BannerSection';
import FeatureSection from '../containers/Saas/FeatureSection';
import VisitorSection from '../containers/Saas/VisitorSection';
import ServiceSection from '../containers/Saas/ServiceSection';
import FaqSection from '../containers/Saas/FaqSection';
import Footer from '../containers/Saas/Footer';
import TestimonialSection from '../containers/Saas/TestimonialSection';
import PartnerSection from '../containers/Saas/PartnerSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';

function getQueryParams() {
  let urlParams = new URLSearchParams(window.location.search);
  return urlParams;
}

export default () => {
  const queryParams = process.browser && getQueryParams();
  const name = queryParams && queryParams.get('name', null);
  const company = queryParams && queryParams.get('company', null);
  const persona = queryParams && queryParams.get('persona', null);
  const focus = queryParams && queryParams.get('focus', null);
  let smbType = queryParams && queryParams.get('smb_type', 'Dealer');
  if (!smbType) {
    smbType = 'Dealer';
  }

  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title={`goBoost | Make Every ${smbType} Your Best ${smbType}`} />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection
            name={name}
            smbType={smbType}
            company={company}
            persona={persona}
            focus={focus}
          />
          <FeatureSection smbType={smbType} />
          <VisitorSection smbType={smbType} />
          <TestimonialSection smbType={smbType} />
          <ServiceSection smbType={smbType} />
          <PartnerSection smbType={smbType} />
          <FaqSection smbType={smbType} />
          <Footer smbType={smbType} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
